import { ServiceHelper } from ".";

const getAgriculturistLimitsApi = async (): Promise<Object> => {
  return await ServiceHelper.get(`agriculturist/limits`,
    {}
  );
};

const updatedLimitApi = async (newData: any): Promise<Object> => {
  return await ServiceHelper.patch(`agriculturist/limit`, newData, {} );
};

const getAgriculturistPrePaidReportApi = async ( startDate:string, finishDate: string, financialData:any,supplierData:any, pageNumber:any, pageSize:any): Promise<Object> => {
  return  await ServiceHelper.get(`buyer/agriculturist/discounts/pre-paid-report?discountCreatedDateBegin=${startDate}&discountCreatedDateEnd=${finishDate}&${financialData}&${supplierData}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
    {}
  );
};

const getPrePaidReportXlsxApi = async (startDate:string, finishDate: string, financialData:any,supplierData:any, pageNumber:any, pageSize:any): Promise<Object> => {
  return  await ServiceHelper.get(`buyer/agriculturist/discounts/pre-paid-report/xlsx?discountCreatedDateBegin=${startDate}&discountCreatedDateEnd=${finishDate}&${financialData}&${supplierData}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
    {}
  );
};


export {
  getAgriculturistLimitsApi,
  updatedLimitApi,
  getAgriculturistPrePaidReportApi,
  getPrePaidReportXlsxApi,
};