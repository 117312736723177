import { ServiceHelper } from ".";

const getAllSuppliers = async (page:any,psize:any): Promise<Object> => {
  return await ServiceHelper.get(`buyer/suppliers?pageNumber=${page}&pageSize=${psize}`,
    {}
  );
};

const postSupplier = async (newData: any): Promise<Object> => {
  return await ServiceHelper.post(`buyer/supplier`, newData, {} );
};

const getAllSuppliersLimit = async (searchQuery:any): Promise<Object> => {
  return  await ServiceHelper.get(`buyer/suppliers/limit?searchQuery=${searchQuery}`,
    {}
  );
};

const deleteSupplierById = async (supId: any): Promise<Object> => {
  return await ServiceHelper.delete(`buyer/supplier/${supId}`,
    {}
  );
};

const updatedLimit = async (newData: any): Promise<Object> => {
  return await ServiceHelper.patch(`buyer/limits`, newData, {} );
};

const getRegisteredTitlesApi = async (): Promise<Object> => {
  return  await ServiceHelper.get(`buyer/supplier/registered/titles`,
    {}
  );
};

const getFinancialTitlesApi = async (): Promise<Object> => {
  return  await ServiceHelper.get(`buyer/financial-institutions/titles`,
    {}
  );
};

const getSuppliersHistoryApi = async (supplierId:any, financialTitleId:any,  startDate:string, finishDate: string, pageNumber:any, pageSize:any): Promise<Object> => {
  return  await ServiceHelper.get(`buyer/risks/suppliers/${supplierId}/history?openingDateBegin=${startDate}${finishDate ? `&openingDateEnd=${finishDate}`: ''}&financialInstitutionIds=${financialTitleId}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
    {}
  );
};

const getXlsxDonwloadApiApi = async (supplierId:any, financialTitleId:any,  startDate:string, finishDate: string, pageNumber:any, pageSize:any): Promise<Object> => {
  return  await ServiceHelper.get(`buyer/risks/suppliers/${supplierId}/history/xlsx?openingDateBegin=${startDate}${finishDate ? `&openingDateEnd=${finishDate}`: ''}&financialInstitutionIds=${financialTitleId}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
    {}
  );
};

const postFarmer = async (newData: any): Promise<Object> => {
  return await ServiceHelper.post(`agriculturist`, newData, {} );
};

const getAllAgriculturistApi = async (): Promise<Object> => {
  return  await ServiceHelper.get(`agriculturist`,
    {}
  );
};

const getRegisteredSupplierApi = async (): Promise<Object> => {
  return  await ServiceHelper.get(`buyer/registered-supplier`,
    {}
  );
};

const getSuppliersUsageReportApi = async ( startDate:string, finishDate: string, financialData:any,supplierData:any, pageNumber:any, pageSize:any): Promise<Object> => {
  return  await ServiceHelper.get(`dashboards/suppliers-usage-report?openingDateBegin=${startDate}&openingDateEnd=${finishDate}&${financialData}&${supplierData}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
    {}
  );
};

const getSuppliersPrePaidReportApi = async ( startDate:string, finishDate: string, financialData:any,supplierData:any, pageNumber:any, pageSize:any): Promise<Object> => {
  return  await ServiceHelper.get(`buyer/suppliers/discounts/pre-paid-report?discountCreatedDateBegin=${startDate}&discountCreatedDateEnd=${finishDate}&${financialData}&${supplierData}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
    {}
  );
};

const getPrePaidReportXlsxApi = async (startDate:string, finishDate: string, financialData:any,supplierData:any, pageNumber:any, pageSize:any): Promise<Object> => {
  return  await ServiceHelper.get(`buyer/suppliers/discounts/pre-paid-report/xlsx?discountCreatedDateBegin=${startDate}&discountCreatedDateEnd=${finishDate}&${financialData}&${supplierData}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
    {}
  );
};

export {
  getAllSuppliers,
  getAllSuppliersLimit,
  deleteSupplierById,
  updatedLimit,
  postSupplier,
  getRegisteredTitlesApi,
  getSuppliersHistoryApi,
  getXlsxDonwloadApiApi,
  getFinancialTitlesApi,
  postFarmer,
  getAllAgriculturistApi,
  getRegisteredSupplierApi,
  getSuppliersUsageReportApi,
  getSuppliersPrePaidReportApi,
  getPrePaidReportXlsxApi
};